import * as React from 'react'
import * as Container from "../../container/container";

const TextBlock3 = (key) => {
    return (
        <Container.Part background="beige" key={key}>
            <Container.Row>
                <div className="col-12">

                        <h2>Die kommunale Gebietsreform in Bayern</h2>
                        <p>
                            Die 1952 erlassene Gemeindeordnung legte Rechte und Aufgaben der Gemeinden fest. Sie sah die
                            Selbstverwaltung vor, ließ aber eine Möglichkeit zur Änderung der Zahl der Gemeinden.
                            Ministerpräsident Alfons Goppel kündigte in einer Regierungserklärung im Januar 1967 eine
                            Gebietsreform an.
                        </p>
                        <p>
                            Landkreise sollten eine Einwohnerzahl von 80.000 haben, kreisfreie Städte eine Einwohnerzahl
                            zwischen 25.000 und 50.000. Eigenständige Gemeinden sollten mindestens 5.000 Einwohner
                            haben, Mitgliedsgemeinden von Verwaltungsgemeinschaften mindestens 1.000 Einwohner. In den
                            neuen Gemeinden sollte die Verwaltung professionalisiert werden.
                            Immer wieder kam es in Gemeinden und Kreisen zu Protesten, teils wurden auch Klagen gegen
                            die Maßnahmen eingereicht. Ein Volksbegehren einer überörtlichen Protestgruppe wurde dennoch
                            nur von 3,7 Prozent der Bürgerinnen und Bürger unterstützt.
                        </p>

                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock3
