import * as React from 'react'
import * as Container from "../../container/container";

const TextBlock1blue = (key) => {
    return (
        <Container.Part background="blue" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>„Beharrlichkeit und Einigkeit brachten den Erfolg.“</h2>
                    <div className="inner-container">
                        <p>
                            Die Bürgerinnen und Bürger von Ermershausen protestierten ab 1978 gegen die von der
                            kommunalen Gebietsreform vorgesehene Eingemeindung nach Maroldsweisach. Sie hielten ihren
                            Protest über 15 Jahre hinweg aufrecht und engagierten sich dafür politisch.
                        </p>
                        <p>
                            Von 1969 bis 1978 wurde in Bayern eine kommunale Gebietsreform mit dem Ziel einer
                            effizienteren Verwaltung durchgeführt. Zudem sollte die kommunale Selbstverwaltung gestärkt
                            werden. Vor allem kleine Gemeinden verloren die Selbstständigkeit. Insgesamt wurde die Zahl
                            der Landkreise und der kreisfreien Städte halbiert, die der Gemeinden von 7.073 auf 2.052
                            reduziert, rund 32.000 kommunale Mandate gingen verloren. Widerstand gegen die Reform gab es
                            besonders in den Orten und Landkreisen, die gezwungen wurden, ihre kommunale
                            Selbstständigkeit aufzugeben.
                        </p>

                        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">

                            <p>
                                Bereits im Vorfeld gab es in Ermershausen Protest gegen die geplante Eingemeindung.
                                Schließlich verweigerte der Ort die Herausgabe der Gemeindeakten an Maroldsweisach. Im
                                Mai 1978 wurde die Eingemeindung daher unter Einsatz der Polizei durchgeführt. In der
                                Nacht auf den 19. Mai wurde der Dorfplatz umstellt und das Rathaus geräumt. Auch weil
                                die Bürgerinnen und Bürger den Polizeieinsatz für unangemessen hielten, setzten sie den
                                Protest fort. Nachdem 1989 der Landtag einen Antrag auf Ausgliederung ablehnte, wählte
                                Ermershausen einen anderen Weg: Unterstützt von CSU-Kreisrat Sebastian Freiherr von
                                Rotenhan trat die Mehrheit der Bevölkerung Ermershausens in eine politische Partei, die
                                CSU ein. Als größter CSU-Ortsverband im Landkreis konnte Ermershausen sich neues Gehör
                                verschaffen. 1994 wurde die Eingemeindung für „gescheitert“ erklärt. Das „Rebellendorf“
                                wurde wieder selbstständig und zum Beispiel für zivilgesellschaftlichen Protest und
                                politisches Engagement.
                            </p>
                        </Container.More>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock1blue
