import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock4Parallax = (key, ortName) => {
    return (
        <Container.Part background="bg-img parallax-bg parallax-ermershausen" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Ermershausen/ermershausen_bild_0009.jpg"}
                           data-glightbox="title: Innenminister Bruno Merk erläutert die Planungen zur Gebietsreform; description: .custom-desc1_7; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Ermershausen/ermershausen_bild_0009.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Innenminister Bruno Merk erläutert die Planungen zur Gebietsreform"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_7">
                            <p>
                                Staatsminister für Landesentwicklung und Umweltfragen Max Streibl, Staatsminister des
                                Innern Bruno Merk, der CSU-Parteivorsitzende Franz Josef Strauß und Ministerpräsident
                                Alfons Goppel (von links).
                            </p>
                            <p className="copy">Abb. HSS-ACSP, Ph P: Merk, Bruno : 1/1</p>
                        </div>
                        <figcaption>
                            Innenminister Bruno Merk erläutert die Planungen zur Gebietsreform
                            <span className="description">
                                Staatsminister für Landesentwicklung und Umweltfragen Max Streibl, Staatsminister des
                                Innern Bruno Merk, der CSU-Parteivorsitzende Franz Josef Strauß und Ministerpräsident
                                Alfons Goppel (von links).</span>
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Ermershausen/ermershausen_bild_0010.jpg"}
                           data-glightbox="title: Protestplakat gegen die Auflösung des Landkreises Alzenau; description: .custom-desc1_9; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Ermershausen/ermershausen_bild_0010.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Protestplakat gegen die Auflösung des Landkreises Alzenau"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_9">
                            <p className="copy">Abb. HSS-ACSP, NL Strauß Pslg PV: 10</p>
                        </div>
                        <figcaption>
                            Protestplakat gegen die Auflösung des Landkreises Alzenau
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Ermershausen/ermershausen_bild_0008.jpg"}
                           data-glightbox="title: Protest gegen die Gebietsreform in Wasserburg vor der Staatskanzlei in München, 1971; description: .custom-desc1_8; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Ermershausen/ermershausen_bild_0008.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Protest gegen die Gebietsreform in Wasserburg vor der Staatskanzlei in München, 1971"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_8">
                            <p>
                                Der Landkreis Wasserburg wurde 1972 im Zuge der Gebietsreform in Bayern aufgelöst und
                                sein Gebiet den Landkreisen Rosenheim, Mühldorf am Inn, Erding und Ebersberg zugeteilt.
                            </p>
                            <p className="copy">Abb. Fritz Neuwirth / Süddeutsche Zeitung Photo</p>
                        </div>
                        <figcaption>
                            Protest gegen die Gebietsreform in Wasserburg vor der Staatskanzlei in München, 1971
                            <span className="description">
                                Der Landkreis Wasserburg wurde 1972 im Zuge der Gebietsreform in Bayern aufgelöst und
                                sein Gebiet den Landkreisen Rosenheim, Mühldorf am Inn, Erding und Ebersberg zugeteilt.</span>
                        </figcaption>
                    </figure>
                    <figure className="center">
                        <StaticImage src="../../../../static/images/Ermershausen/silhouette-glocke mit bannern.png" width={335} layout="fixed" placeholder="none" alt=""/>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock4Parallax
