import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock2White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Ermershausen/ermershausen_bild_0002.jpg"}
                           data-glightbox="title: Blockade der Bundesstraße in Ermershausen, Mai 1978; description: .custom-desc1_3; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Ermershausen/ermershausen_bild_0002.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Blockade der Bundesstraße in Ermershausen, Mai 1978"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_3">
                            <p>
                                Als Reaktion auf den Polizeieinsatz blockierten die Protestierenden in Ermershausen mit
                                einem alten Auto und einem Eichenstamm die Bundesstraße.
                            </p>
                            <p className="copy">Abb. Gemeindearchiv Ermershausen</p>
                        </div>
                        <figcaption>
                            Blockade der Bundesstraße in Ermershausen, Mai 1978
                            <span className="description">
                                Als Reaktion auf den Polizeieinsatz blockierten die Protestierenden in Ermershausen mit
                                einem alten Auto und einem Eichenstamm die Bundesstraße.</span>
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Ermershausen/ermershausen_bild_0006.jpg"}
                           data-glightbox="title: Mahnwache in Ermershausen, um 1980; description: .custom-desc1_5; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Ermershausen/ermershausen_bild_0006.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Mahnwache in Ermershausen, um 1980"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_5">
                            <p>
                                In den Jahren ab 1978 wurde regelmäßig in der Nacht zum 19. Mai eine Mahnwache
                                veranstaltet.
                            </p>
                            <p className="copy">Abb. Camapresse</p>
                        </div>
                        <figcaption>
                            Mahnwache in Ermershausen, um 1980
                            <span className="description">
                                In den Jahren ab 1978 wurde regelmäßig in der Nacht zum 19. Mai eine Mahnwache veranstaltet.
                            </span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Ermershausen/ermershausen_bild_0003.jpg"}
                           data-glightbox="title: Wahlboykott in Ermershausen, um 1980; description: .custom-desc1_4; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Ermershausen/ermershausen_bild_0003.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Wahlboykott in Ermershausen, um 1980"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_4">
                            <p>
                                Zu den Protestformen in Ermershausen gehörte auch, dass die Teilnahme an Wahlen
                                verweigert wurde.
                            </p>
                            <p className="copy">Abb. Camapresse</p>
                        </div>
                        <figcaption>
                            Wahlboykott in Ermershausen, um 1980
                            <span className="description">
                                Zu den Protestformen in Ermershausen gehörte auch, dass die Teilnahme an Wahlen
                                verweigert wurde.</span>
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Ermershausen/ermershausen_bild_0007.jpg"}
                           data-glightbox="title: Feier zur Selbstständigkeit von Ermershausen, 1994; description: .custom-desc1_6; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Ermershausen/ermershausen_bild_0007.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Feier zur Selbstständigkeit von Ermershausen, 1994"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_6">
                            <p>
                                1994 wurde Ermershausen wieder selbstständig und Mitglied der Verwaltungsgemeinschaft
                                Hofheim. Der vormalige Bürgermeister Adolf Höhn wurde wieder in sein Amt gewählt.
                            </p>
                            <p className="copy">Abb. Camapresse</p>
                        </div>
                        <figcaption>
                            Feier zur Selbstständigkeit von Ermershausen, 1994
                            <span className="description">
                                1994 wurde Ermershausen wieder selbstständig und Mitglied der Verwaltungsgemeinschaft
                                Hofheim. Der vormalige Bürgermeister Adolf Höhn wurde wieder in sein Amt gewählt.</span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock2White
