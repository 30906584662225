import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Headerimage = (key, ortName) => {
    return (
        <Container.HeaderPart background="Ermershausen" key={key}>
            <div className="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>
                                1978<br/>Ermershausen<br/><br/>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="box">
                            <ul>
                                <li>Zivilgesellschaft</li>
                                <li>Kommunales</li>
                                <li>Interessenvertretung</li>
                            </ul>
                            <p>
                                Die Bürgerinnen und Bürger von Ermershausen erreichten durch jahrelange Proteste und
                                politisches Engagement, dass ihre Gemeinde nach der Gebietsreform die kommunale
                                Selbstständigkeit doch wiedererlangen konnte.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Ermershausen/ermershausen_bild_0004.jpg"}
                                   data-glightbox="title: Ermershausener Bürgerinnen und Bürger auf dem Rathausplatz, 1980; description: .custom-desc1_1; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Ermershausen/ermershausen_bild_0004.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Ermershausener Bürgerinnen und Bürger auf dem Rathausplatz, 1980"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_1">
                                    <p>
                                        Als Protest gegen die Eingemeindung brachten die Bürgerinnen und Bürger
                                        Transparente am Rathaus an. Die Glocke, die anfänglich als Warnsignal verwendet
                                        wurde, wurde bald als „Freiheitsglocke“ zum Symbol für den Protest der Gemeinde.
                                    </p>
                                    <p className="copy">Abb. Gemeindearchiv Ermershausen</p>
                                </div>
                                <figcaption>
                                    Ermershausener Bürgerinnen und Bürger auf dem Rathausplatz, 1980
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Ermershausen/ermershausen_bild_0005.jpg"}
                                   data-glightbox="title: Polizeieinsatz in Ermershausen, Mai 1978; description: .custom-desc1_2; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Ermershausen/ermershausen_bild_0005.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Polizeieinsatz in Ermershausen, Mai 1978"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_2">
                                    <p>
                                        In der Nacht auf den 19. Mai waren laut Angaben in der Presse rund 1.800
                                        Polizisten in Ermershausen im Einsatz, um die Eingemeindung durchzusetzen. Der
                                        Ort selbst hatte etwa 600 Einwohnerinnen und Einwohner.
                                    </p>
                                    <p className="copy">Abb. Gemeindearchiv Ermershausen</p>
                                </div>
                                <figcaption>
                                    Polizeieinsatz in Ermershausen, Mai 1978
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </Container.HeaderPart>
    )
}

export default Headerimage
